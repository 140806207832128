import "../styles/fs2.css";
import "../styles/animations.css";
import "../styles/tiny-slider.css";

import React, { useEffect } from "react";

import ImageSm from "./ImageSm";
import { Link } from "gatsby";

const HomeSlider = () => {
  useEffect(() => {
    // https://github.com/ionic-team/stencil/issues/1724
    // Fix for ReferenceError: window is not defined

    const { tns } = require("tiny-slider/src/tiny-slider.js");
    // forEach function
    const forEach = function (array, callback, scope) {
      for (let i = 0; i < array.length; i++) {
        callback.call(scope, i, array[i]); // passes back stuff we need
      }
    };
    // Carousel initialisation
    const carousels = document.querySelectorAll(".cz-carousel .cz-carousel-inner");
    let slider;
    forEach(carousels, function (value, index) {
      const defaults = {
        container: index,
        controlsText: ['<i className="czi-arrow-left"></i>', '<i className="czi-arrow-right"></i>'],
        navPosition: "bottom",
        mouseDrag: true,
        speed: 1000,
        autoplay: true,
        autoplayTimeout: 4500,
        autoplayHoverPause: false,
        autoplayButtonOutput: false,
        controls: false,
        nav: false,
        loop: false,
        rewind: true,
        mode: "gallery",
      };
      let userOptions;
      if (index.dataset.carouselOptions !== undefined) userOptions = JSON.parse(index.dataset.carouselOptions);
      const options = { ...defaults, ...userOptions };
      slider = tns(options);
    });
    return () => {
      slider.destroy();
    };
  }, []);
  return (
    <div className="cz-carousel py-2 py-sm-4 h-100 bg-white">
      <div className="cz-carousel-inner p-2 p-sm-3 pr-sm-4 pb-3">
        {/* Punsch Catering*/}
        <div>
          <div className="row align-items-center">
            <div
              className="col-md-6 order-md-2 box-shadow-img rounded mb-4 mb-sm-0"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <ImageSm
                className="d-block mx-auto rounded"
                filename="highend-schinken-kase-ei-tomate-klein.webp"
                alt="Crepes Lieferung"
              />
            </div>
            <div className="col-lg-5 col-md-6 offset-lg-1 order-md-1 pt-sm-4 pb-md-4 text-center text-md-left">
              <h2 className="font-weight-light pb-1 from-left">Salzige Buchweizen-Crêpes</h2>
              <h1 className="display-4 from-left delay-1 smalltitle">CRÊPES</h1>
              <h3 className="font-weight-light pb-3 from-left delay-2">Süße Crêpes</h3>
              <Link className="btn btn-primary btn-shadow scale-up delay-4" to="/produkte/crepes" alt="produkte">
                Jetzt Bestellen<i className="czi-arrow-right ml-2 mr-n1"></i>
              </Link>
            </div>
          </div>
        </div>
        {/* Firmengeschenke*/}
        <div>
          <div className="row align-items-center">
            <div
              className="col-md-6 order-md-2 box-shadow-img rounded mb-4 mb-sm-0"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <ImageSm className="d-block mx-auto rounded" filename="gifts.jpg" alt="Crepes Lieferung" />
            </div>
            <div className="col-lg-5 col-md-6 offset-lg-1 order-md-1 pt-sm-4 pb-md-4 text-center text-md-left">
              <h2 className="font-weight-light pb-1 from-left">Firmengeschenke</h2>
              <h1 className="display-4 from-left delay-1 smalltitle">Mitarbeitergeschenke</h1>
              <h3 className="font-weight-light pb-3 from-left delay-2">Kundengeschenke</h3>
              <Link className="btn btn-primary btn-shadow scale-up delay-4" to="/produkte" alt="produkte">
                Jetzt Bestellen<i className="czi-arrow-right ml-2 mr-n1"></i>
              </Link>
            </div>
          </div>
        </div>
        {/* Punsch @ home*/}
        <div>
          <div className="row align-items-center">
            <div
              className="col-md-6 order-md-1 box-shadow-img rounded mb-4 mb-sm-0"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <ImageSm className="d-block mx-auto rounded" filename="img_5070.jpg" alt="Punsch Lieferung" />
            </div>
            <div className="col-lg-5 col-md-6 offset-lg-1 order-md-2 pt-sm-4 pb-md-4 text-center text-md-left">
              <h2 className="font-weight-light pb-1 from-bottom">Ready to drink</h2>
              <h1 className="display-4 from-bottom delay-1">HEISSER PUNSCH</h1>
              <h3 className="font-weight-light pb-3 from-bottom delay-2">Thermokanne u Häferl</h3>
              <Link
                className="btn btn-primary btn-shadow scale-up delay-4"
                to="produkte/heisser-punsch"
                alt="punsch-box"
              >
                Produkte<i className="czi-arrow-right ml-2 mr-n1"></i>
              </Link>
            </div>
          </div>
        </div>
        {/* Punsch Catering*/}
        <div>
          <div className="row align-items-center">
            <div
              className="col-md-6 order-md-2 box-shadow-img rounded mb-4 mb-sm-0"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <ImageSm className="d-block mx-auto rounded" filename="2cvbar.webp" alt="Crepes Lieferung" />
            </div>
            <div className="col-lg-5 col-md-6 offset-lg-1 order-md-1 pt-sm-4 pb-md-4 text-center text-md-left">
              <h2 className="font-weight-light pb-1 from-left">Punsch + Equipment</h2>
              <h1 className="display-4 from-left delay-1 smalltitle">PUNSCH-CATERING</h1>
              <h3 className="font-weight-light from-left delay-2">in 2 Std bei Dir</h3>
              <h3 className="font-weight-light pb-3 scale-up delay-3">oder Vor-Bestellung für Wunschtermin</h3>
              <Link
                className="btn btn-primary btn-shadow scale-up delay-4"
                to="produkte/punsch-catering"
                alt="produkte"
              >
                Jetzt Bestellen<i className="czi-arrow-right ml-2 mr-n1"></i>
              </Link>
            </div>
          </div>
        </div>
        {/* punsch in flaschen */}
        <div>
          <div className="row align-items-center">
            <div
              className="col-md-6 order-md-2 box-shadow-img rounded mb-4 mb-sm-0"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <ImageSm
                className="d-block mx-auto rounded"
                filename="hauspunsch01.jpg"
                alt="Punsch Flaschen Lieferservice"
              />
            </div>
            <div className="col-lg-5 col-md-6 offset-lg-1 order-md-1 pt-sm-4 pb-md-4 text-center text-md-left">
              <h2 className="font-weight-light pb-1 scale-up">Hausgemachter</h2>
              <h1 className="display-4 scale-up delay-1">Punsch in Flaschen</h1>
              <h5 className="font-weight-light pb-3 scale-up delay-2">18 Sorten Punsch und Glühwein</h5>
              <Link className="btn btn-primary btn-shadow scale-up delay-4" to="/produkte" alt="produkte">
                Produkte<i className="czi-arrow-right ml-2 mr-n1"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSlider;
