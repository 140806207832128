import HomeSlider from "../components/HomeSlider";
import HowItWorks from "../components/HowItWorks";
import ImageSm from "../components/ImageSm";
import { Link } from "gatsby";
import React from "react";
import Verification from "../components/Verification";
const Home = () => {
  return (
    <>
      <section className="container p-0 ">
        <Verification />
        <HomeSlider />
        <div
          className="container mt-sm-2 mb-4 mb-sm-4"
          style={{ paddingTop: "35px" }}
        >
          <div className="flex align-items-center text-center">
            <h1 className="align-items-center">
              Wieder-Eröffnung am 01.11.2022
            </h1>
          </div>
        </div>
        <HowItWorks />
        <div
          className="container mt-sm-2 mb-4 mb-sm-4"
          style={{ backgroundColor: "white", padding: 0 }}
        >
          <div className="row">
            <div
              className="col-12 col-sm-6 order-sm-1 order-2"
              style={{ padding: 0, margin: 0, backgroundColor: "#ffe6e6" }}
            >
              <ImageSm
                className=" mx-auto w-100"
                filename="2cvVignette2.jpg"
                alt="Heißer Punsch"
                style={{ height: 100 + "%" }}
              />
            </div>
            <div
              className="col-12 col-sm-6 order-sm-2 order-1 text-center p-4 pt-4 d-flex flex-column justify-content-center"
              style={{}}
            >
              <h3 className="font-weight-light pb-1 from-bottom">
                Lieferung Deiner Punsch-Bestellung
              </h3>
              <h2 className="font-weight-normal pb-1 from-bottom delay-1">
                in max. 2 Std.
              </h2>
              <h4 className="font-weight-light pb-3 delay-2 from-bottom">
                oder Vor-Bestellung für Wunschtermin
              </h4>
              <div>
                <Link
                  className="btn btn-primary btn-shadow scale-up delay-4"
                  to="/produkte"
                >
                  Produkte<i className="czi-arrow-right ml-2 "></i>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container mt-sm-2 mb-4 mb-sm-4"
          style={{ backgroundColor: "white", padding: 0 }}
        >
          <div className="row">
            <div
              className="col-12 col-md-6 text-center  p-sm-2 p-4 d-flex flex-column justify-content-center"
              style={{ backgroundColor: "white", padding: 0, margin: 0 }}
            >
              <h2 className="font-weight-light pb-1 from-bottom">
                Wir liefern täglich{" "}
              </h2>
              <h2 className="font-weight-light pb-1 from-bottom delay-1">
                Von 11 bis 23 Uhr
              </h2>
              <h2 className="font-weight-normal pb-3 from-bottom delay-2">
                Auch an den Feiertagen
              </h2>
              <div>
                <Link
                  className="btn btn-primary btn-shadow scale-up delay-4"
                  to="/produkte"
                >
                  Produkte<i className="czi-arrow-right ml-2 "></i>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-6 " style={{ padding: 0, margin: 0 }}>
              <ImageSm
                className=" mx-auto w-100"
                filename="2cvsnow3.jpg"
                alt="Heißer Punsch"
              />
            </div>
          </div>
        </div>

        <div
          className="container mt-sm-2 mb-4 mb-sm-4"
          style={{ backgroundColor: "white", padding: 0 }}
        >
          <div className="row">
            <div
              className="col-12 col-sm-6 order-sm-1 order-2"
              style={{ padding: 0, margin: 0 }}
            >
              <ImageSm
                className=" mx-auto w-100"
                filename="gifts.jpg"
                alt="Heißer Punsch"
                style={{ height: 100 + "%" }}
              />
            </div>
            <div
              className="col-12 col-sm-6 order-sm-2 order-1 text-center p-4 pt-4 d-flex flex-column justify-content-center"
              style={{}}
            >
              <h3 className="font-weight-light pb-1 from-bottom">
                B2B Firmengeschenke u. Weihnachtsgeschenke
              </h3>
              <h2 className="font-weight-normal from-bottom delay-1">
                Mitarbeitergeschenke Kundengeschenke
              </h2>
              <h4 className="font-weight-light pb-3 from-bottom delay-2">
                Großbestellungen in max. 48 Std zugestellt
              </h4>
              <div>
                <a
                  className="btn btn-outline-secondary btn-shadow scale-up delay-4"
                  href="mailto:office@punsch-taxi.at?subject=Company Info"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="czi-mail mr-2"></i>Kontaktieren Sie uns!
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container mt-sm-2 mb-4 mb-sm-4"
          style={{ backgroundColor: "white", padding: 0 }}
        >
          <div className="row">
            <div
              className="col-12 col-md-6 text-center  p-sm-2 p-4 d-flex flex-column justify-content-center"
              style={{ backgroundColor: "white", padding: 0, margin: 0 }}
            >
              <h3 className="font-weight-light pb-1 from-bottom">
                Punsch Mobil ist Dein Lieferservice für
              </h3>
              <h2 className="font-weight-normal from-bottom delay-1">
                Punsch und Glühwein
              </h2>
              <h4 className="font-weight-light pb-3 from-bottom delay-2">
                Heiß in der Thermobox - kalt in der Flasche - verpackt als
                Geschenk
              </h4>
              <div>
                <Link
                  className="btn btn-primary btn-shadow scale-up delay-4"
                  to="/produkte"
                >
                  Produkte<i className="czi-arrow-right ml-2 "></i>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-6 " style={{ padding: 0, margin: 0 }}>
              <ImageSm
                className=" mx-auto w-100"
                filename="girls.jpg"
                alt="Heißer Punsch"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;

export const Head = () => {
  const OGIMAGE = `https://www.punsch-mobil.at/img/sacher2cv.jpeg`;
  const TITLE = `Punsch Mobil - Dein Punsch-Lieferservice in Wien u. Umgebung`;
  const DESCRIPTION = `Punsch- Lieferservice und Punsch- Catering in Wien und Umgebung. Lieferservice von Punsch in Wien u. Umgebung, Punsch-Kocher, Punsch-Stand, Stehtische, Punsch-Truck`;
  const PAGEURL = `https://www.punsch-mobil.at`;
  return (
    <>
      <title>{TITLE}</title>
      <link rel="canonical" href={PAGEURL} />
      <link
        rel="icon"
        type="image/png"
        href="https://www.punsch-mobil.at/favicon.png"
      />
      <meta name="description" content={DESCRIPTION} />
      <meta
        name="keywords"
        content={[
          "punsch lieferung",
          "crepes lieferservice",
          "lieferung",
          "wien",
          "punsch mobil",
          "punsch lieferservice",
          "punsch zustellung",
        ]}
      />
      <meta property="og:url" content={PAGEURL} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={TITLE} />
      <meta property="og:description" content={DESCRIPTION} />
      <meta property="og:image" content={OGIMAGE} />
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:site"
        content={"lacreperiemobile" && `@lacreperiemobile`}
      />
      <meta name="twitter:title" content={TITLE} />
      <meta name="twitter:description" content={DESCRIPTION} />
      <meta name="twitter:image" content={OGIMAGE} />

      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Website",
          "url": "${PAGEURL}",
          "description": "${DESCRIPTION}",
          "image": "${OGIMAGE}",
          "sameAs" : [
            "https://www.facebook.com/punscheninwien",
            "http://creperie-mobile.at/",
            "http://vintage-foodtrucks.at/", 
            "https://www.instagram.com/punscheninwien/"
            ],
           "address": {
             "@type": "PostalAddress",
             "streetAddress": "Frauschereck 23, 5242 St. Johann",
             "addressRegion": "AT",
             "postalCode": "5242",
             "addressCountry": "AT"
           }

        }
      `}
      </script>
    </>
  );
};
