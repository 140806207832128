/*global Modal*/

import React, { useContext, useEffect, useState } from "react";

import { UserContext } from "../userContext";

const Verification = () => {
  const { user, saveUserObj } = useContext(UserContext);
  const [age, setage] = useState(false);
  const [cookies, setcookies] = useState(false);
  const handleClick = () => {
    console.log("first");
    console.log(user);
    setage(true);
  };
  useEffect(() => {
    let user = { age: age, cookie: cookies };
    saveUserObj(user);
    return () => {};
  }, [age, cookies]);

  useEffect(() => {
    setage(user.age);
    setcookies(user.cookie);
    if (typeof document !== `undefined`) {
      import("bootstrap/js/dist/modal.js").then((a) => {
        let Modal = a.default;
        var myModal = new Modal(document.getElementById("modalCentered"), {
          backdrop: "static",
          keyboard: false,
        });

        if (!user.age) {
          myModal.show();
        } else {
          myModal.hide();
        }
      });
      import("bootstrap/js/dist/alert.js").then((a) => {
        let Alert = a.default;
        var alertList = document.querySelectorAll(".alert");
        alertList.forEach(function (alert) {
          new Alert(alert);
        });
      });
    }
    return () => {};
  }, []);

  return (
    <div className="">
      {user.hasOwnProperty("cookie") && !user.cookie && (
        <div
          className="alert alert-dark alert-dismissible fade show text-center w-100"
          role="alert"
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            zIndex: 3000,
            margin: 0,
          }}
        >
          <div className="mb-4">
            Wir verwenden Cookies, um Ihnen den bestmöglichen Service zu
            liefern. Durch die Nutzung unserer Website stimmen Sie zu, dass
            Cookies auf Ihrem Gerät gespeichert werden.
          </div>

          <button
            type="button"
            className="btn btn-outline-secondary"
            data-bs-dismiss="alert"
            aria-label="Accept"
            onClick={() => setcookies(true)}
          >
            <span aria-hidden="false" className="text-font-sm">
              Einverstanden
            </span>
          </button>
        </div>
      )}

      {/*  MODAL */}
      <div
        className="modal fade show"
        id="modalCentered"
        tabIndex="-1"
        role="dialog"
        aria-modal="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="modalCentered"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h4 className="modal-title text-center mb-4">
                Achtung, heißer Inhalt!
              </h4>
              <p className="font-size-md">
                Da unser webshop alkoholische Getränke verkauft, musst Du
                mindestens 18 Jahre alt sein, um ihn aufzusuchen.
              </p>
              <h5 className="text-center">Bist du mindestens 18 Jahre alt?</h5>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-dark btn-shadow"
                type="button"
                data-bs-dismiss="modal"
                onClick={handleClick}
              >
                Ja
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
